<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />
        <div v-else>
          <catalogo-dialog
            v-model="dialog"
            :id-dialog="idDialog"
          />
          <crud-list
            v-model="catalogo"
            :headers="headers"
            :slots="['item.volume', 'item.preco', 'item.disponibilidade']"
            :permission="'visualizar_catalogo'"
            :actions="actions"
            :sort-by="['unidade_negocio_nome_fantasia', 'codigo']"
            :sort-desc="[false]"
            export-pdf-xlsx
          >
            <template
              v-if="hasPermission('inserir_item_catalogo')"
              v-slot:btnCadastro
            >
              <v-col>
                <v-btn
                  class="text-none text-white"
                  block
                  color="primary"
                  title="Adicionar Produto"
                  rounded
                  @click="OPEN_FORM"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Adicionar
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.volume`]="{ item }">
              <span>
                {{ `${currencyFormatter(item.volume, 3)}` }}
              </span>
            </template>
            <template v-slot:[`item.preco`]="{ item }">
              <span>
                {{ `${item.preco}` }}
              </span>
            </template>
            <template v-slot:[`item.disponibilidade`]="{ item }">
              <span>
                {{ item.disponibilidade === 1 ? 'Imediata' : item.disponibilidade === 2 ? 'Sob cotação' : 'Sob demanda' }}
              </span>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import CatalogosStore, { BOOTSTRAP, REMOVE_ITEM_FROM_CATALOGO } from '@/store/modules/catalogos'
  import CatalogoDialog from './CatalogoDialog'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CatalogoFormStore, { OPEN_FORM } from '@/store/modules/forms/catalogo'
  import store from '@/store'
  import { sortCurrency } from '../../utils/sorts'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    components: {
      CatalogoDialog,
    },
    data () {
      return {
        actions: [
          {
            title: 'Editar produto',
            color: 'info darken-3',
            click: item => this.OPEN_FORM({ item }),
            icon: 'mdi-pencil',
            permission: 'editar_item_catalogo',
          },
          {
            title: 'Excluir Produto',
            color: 'red darken-3',
            click: item => this.REMOVE_ITEM_FROM_CATALOGO(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_item_catalogo',
          },
        ].filter(({ permission }) => store.getters.hasPermission(permission)),
        produtos: [],
        idDialog: null,
        loading: true,
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, width: '40px' },
          { align: 'start', sortable: true, groupable: true, text: 'Unidade de Negócio', value: 'unidade_negocio_nome_fantasia', width: 'auto', floatingfilter: true, report: true },
          { align: 'start', sortable: true, groupable: true, text: 'Categoria', value: 'categoria.nome', width: 'auto', floatingfilter: true, report: true },
          { align: 'right', sortable: true, groupable: false, text: 'Código', value: 'codigo', width: 'auto', floatingfilter: true, report: true, styleReport: { halign: 'right' } },
          { align: 'start', sortable: true, groupable: false, text: 'Descrição', value: 'nome', width: 'auto', floatingfilter: true, report: true },
          { align: 'start', sortable: true, groupable: false, text: 'UND', value: 'unidade.nome', width: 'auto', floatingfilter: true, report: true },
          {
            align: 'start',
            sortable: true,
            groupable: false,
            text: 'Volume',
            value: 'volume',
            width: 'auto',
            sort: sortCurrency,
            report: true,
            styleReport: { halign: 'right', type: 'number' },
            styleXLSX: { type: 'number' },
          },
          {
            align: 'right',
            sortable: true,
            groupable: false,
            text: 'Preço',
            value: 'preco',
            width: 'auto',
            sort: sortCurrency,
            report: true,
            styleReport: { halign: 'right' },
            styleXLSX: { type: 'number' },
          },
          { align: 'right', sortable: true, groupable: false, text: 'Pedido Mínimo', value: 'pedido_min', width: 'auto', report: true, styleReport: { halign: 'right' } },
          {
            align: 'start',
            sortable: true,
            groupable: false,
            text: 'Disponibilidade',
            value: 'disponibilidade',
            width: 'auto',
            report: true,
            styleXLSX: { type: 'alterValue' },
            alterValue: { 2: 'sob cotação', 1: 'imediata', 0: 'sob demanda' },
          },
          { align: 'start', sortable: true, groupable: true, text: 'Fornecedor', value: 'fornecedor', width: 'auto', floatingfilter: true },
        ],
        currencyFormatter,
      }
    },
    computed: {
      ...mapState('catalogos', ['unidades_negocio', 'catalogo', 'ready', 'dialog']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.state.form.catalogo) { this.$store.registerModule(['form', 'catalogo'], CatalogoFormStore) }
      if (!this.$store.state.catalogos) { this.$store.registerModule('catalogos', CatalogosStore) }
      this.BOOTSTRAP()
      this.loading = false
    },
    methods: {
      ...mapActions('catalogos', [BOOTSTRAP, REMOVE_ITEM_FROM_CATALOGO]),
      ...mapActions('form/catalogo', [OPEN_FORM]),
    },
  }
</script>
